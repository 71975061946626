import React from "react";

import * as styles from "./Content.module.scss";
import avatarImg from './benas.png';

interface Props {
  title: string;
  body: string;
}

const Content: React.FC<Props> = ({ body, title, date }: Props) => (
  <div className={styles.content}>
    <h1 className={styles.title}>{title}</h1>
    <div className={styles.author}>
      <img src={avatarImg} alt="Benas Bitvinskas" className={styles.avatar} />
        <p className={styles.authorText}>Benas Bitvinskas<br></br>
          <i>
            {new Date(date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
          </i>
        </p>
    </div>
    <div className={styles.body} dangerouslySetInnerHTML={{ __html: body }} />
  </div>
);

export default Content;